/* Define default styles for the sidebar */
.sidebar {
    width: 250px !important; /* Adjust as needed */
    /* Add any other styles here */
  }
  
  /* Apply responsive styles for smaller screens */
  @media screen and (max-width: 768px) {
    .sidebar {
      width: 100% !important; /* Use full width on smaller screens */
      /* Add any other styles for smaller screens here */
    }
  }
  