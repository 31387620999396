.cus-table{

    text-align: center;
    font-size: small;
    
  }
  .table thead th{
    vertical-align: middle;
  }

  @media (max-width: 900px) {
    .d-flex.flex-wrap.align-items-center {
      flex-direction: column;
    }
  
    .btn-group {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
  
    .btn-group .dropdown-toggle {
      display: block;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  
  @keyframes typing-pause {
    0%, 90% {
      width: 0;
    }
    5%, 95% {
      width: 100%;
    }
  }

  .typed-text {
    overflow: hidden;
    white-space: nowrap;
    border-right: .15em solid orange; /* Adjust the border properties for the typing cursor */
    animation: typing-pause 13s steps(20) infinite;
  }

  .filter-select
  {
    width: 150px !important;
    background-color: #ffc107 !important;
  }

  .table-column-width{

    width: 100rem !important;
  }


