/* LoginForm.css */
.login-page {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden; /* Ensure the overlay doesn't overflow */

  /* Your existing background image properties */
  background-image: url('../../../assets/images/loginBg.jpg');
  background-size: cover;
  background-position: center;
}

.login-page::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust alpha value for darkness */
}

.card {
  background: rgba(255, 255, 255, 0.9); /* Slightly transparent background for the form */
  border-radius: 10px;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;

}
.logo-container {
  position: relative;
  width: 100px; /* Adjust as needed */
  height: 100px; /* Adjust as needed */
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: scale(0.5) rotate(0deg);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.logo1 {
  animation: showLogo1 4s infinite;
}

.logo2 {
  animation: showLogo2 4s infinite;
}

@keyframes showLogo1 {
  0%, 25% {
      opacity: 1;
      transform: scale(1) rotate(0deg);
  }
  50%, 100% {
      opacity: 0;
      transform: scale(0.5) rotate(180deg);
  }
}

@keyframes showLogo2 {
  0%, 50% {
      opacity: 0;
      transform: scale(0.5) rotate(180deg);
  }
  75%, 100% {
      opacity: 1;
      transform: scale(1) rotate(0deg);
  }
}

.zoom-in {
  animation: zoomIn 0.5s ease-in-out;
}

@keyframes zoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

