.gradient {
    background: linear-gradient(to right, white, black);
  }


  @media only screen and (max-width: 868px) and (orientation: portrait)
{
  .header-comp{
   align-items: flex-end !important;
  }}

  .centered-container {
    display: flex;
    justify-content: center;
    align-items: center;
  
  }
  .logo-container {
    position: relative;
    width: 80px; /* Adjust as needed */
    height: 80px; /* Adjust as needed */
  }

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scale(0.5) rotate(0deg);
    transition: opacity 5s ease-in-out, transform 5s ease-in-out;
  }
  
  .logo1 {
    animation: showLogo1 15s infinite;
  }
  
  .logo2 {
    animation: showLogo2 15s infinite;
  }
  
  @keyframes showLogo1 {
    0%, 25% {
        opacity: 1;
        transform: scale(1) rotate(0deg);
    }
    50%, 100% {
        opacity: 0;
        transform: scale(0.5) rotate(180deg);
    }
  }
  
  @keyframes showLogo2 {
    0%, 50% {
        opacity: 0;
        transform: scale(0.5) rotate(180deg);
    }
    75%, 100% {
        opacity: 1;
        transform: scale(1) rotate(0deg);
    }
  }