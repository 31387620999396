.toggled {
  overflow: visible;
  width: 6.5rem !important;
}
.toggled1 {
  width: 0 !important;
  overflow: hidden;
}

.SideMenuAndPageContent {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.PageContent {
  justify-content: flex-start;
  flex: 1;
  overflow: scroll;
  overflow-x: hidden;
}

.cus-textInput-style {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  font-size: smaller !important;
}
.cus-label {
  font-size: small !important;
  color: gray;
}

.pointer-cursor {
  cursor: pointer;
}




.excel-table {
  border-collapse: collapse;
  width: 100%;
}
 .excel-table td {
  border: 1px solid #ddd;
  padding:6px !important;
  text-align: center;
  word-wrap: break-word !important ; 
  overflow-wrap: break-word !important;
  font-size:small;
}

.excel-table th {
  border: 1px solid #ddd;
  padding:6px !important;
  background-color: #f2f2f2;
  font-size:x-small;
  text-align: center;
}

select:focus {
  outline: none !important; 
}

.custom-h6 {
  background-color: black !important;
  color: white !important;
  padding: 12px 20px;
  font-weight: 600 !important;
  height: 40px;
  width: fit-content;
  font-size: 14px;
  border-bottom-right-radius: 20px;
}




